<template>
  <!-- 案例详情 -->
  <div id="caseDetailPage">
    <div class="caseDetail">
      <div class="breadNav">
        <div class="nav_info flex">您的位置: {{store.storeName}} > 案例中心 > 案例详情</div>
      </div>
      <div class="shopContainer flex align-row">
        <div class="leftSection">
          <div class="titleBox">
            <p class="title">{{detail.name}}</p>
            <div class="content">
              <p>
                <label for>时间：</label>
                <span v-text="detail.createTime"></span>
              </p>
              <p>
                <label for>类型：</label>
                <span v-text="detail.cateName"></span>
              </p>
              <p class="backBg">
                <label>背景：</label>
                <span class="textOver">{{detail.desciption}}</span>
              </p>
            </div>
          </div>
          <div class="detailBox">
            <p class="name">案例详情</p>
            <div v-html="detail.detail" class="detailImg"></div>
          </div>
        </div>
        <div class="rightSection flex-1">
          <div class="header1">商家其他案例</div>
          <div class="shopCaseWrap" v-for="(item,index) in shop" :key="index">
            <img :src="item.coverUrl | imagePath" alt @click="otherCase(item.id)">
            <span @click="otherCase(item.id)">{{item.name}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BreadCrumb from '@/components/common/breadCrumb'
import {mapGetters} from 'vuex'
export default {
  components: { BreadCrumb },
  data() {
    return {
      detail: {}, // 详情内容
      shop: {} // 右侧商家信息
    }
  },
  computed: {
    ...mapGetters(['store'])
  },
  mounted() {
    let storeId=this.$route.query.storeId
    let id =this.$route.query.id
    this.getcaseDetail(id)
    this.getshopData(storeId) //调用其他案例
    document.getElementsByClassName("router")[0].style.padding="0px"
  },
  methods: {
    otherCase(id){ //点击其他案例
      this.getcaseDetail(id)
    },
    // 获取案例详情信息
    getcaseDetail(id) {
      this.$http.get('/store/case', { params: { id: id } }).then(res => {
        const { data } = res.data
        this.detail = data
      })
    },

    // 右侧其他商品案例
    getshopData(storeId,id) {
      let req = {
        pageNum: 0,
        pageSize: 10,
        storeId: storeId,
      }
      this.$http.get('/store/case/list', { params: req }).then(res => {
        this.shop = res.data.data.data;
      })
    }
  }
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/change.scss';
#caseDetailPage {
  margin-top: 135px;
}

.caseDetail {
  width: 100%;
  padding-bottom: 30px;
  background: #f5f5f5;
}



.shopContainer {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  .leftSection {
    width: 890px;
    padding: 30px 20px;
    margin-right: 20px;
    background-color: #fff;
    .titleBox {
      .title {
        color: #333;
        font-size: 18px;
      }
      .content {
        padding-top: 20px;
        p {
          max-width: 850px;
          // height: 32px;
          line-height: 32px;
        }
        label {
          display: inline-block;
          color: #999;
          font-size: 14px;
          padding-bottom: 10px;
        }
        span {
          font-size: 14px;
          color: #666;
        }
        .backBg{
          display: flex;
          :first-child{
            width: 42px;
            padding:0;
            flex-shrink: 0;
          }
          .textOver {
            word-break: break-all;
            overflow: hidden;
          }
        }
      }
    }
    .detailBox {
      margin-top: 50px;
      .name {
        color: #333;
        font-size: 14px;
        padding-bottom: 20px;
      }

    }
  }

    
  .rightSection {
    background-color: #fff;
    padding-bottom: 20px;
    height: 100%;
    .header1 {
      height: 40px;
      line-height: 40px;
      background: #0db168;
      color: #fff;
      font-size: 15px;
      text-align: center;
    }
    .shopCaseWrap {
      &:first-child {
        padding-top: 35px;
      }
      max-width: 290px;
      padding: 20px;
      padding-bottom: 0;
      color: #000;
      img {
        width: 250px;
        height: 150px;
        &:hover {
          cursor: pointer;
          background: #888;
          opacity: 0.5;
        }
      }
      span {
        display: block;
        text-align: center;
        font-size: 16px;
        height: 56px;
         line-height: 56px;
        color: #333;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        &:hover {
          color: #0db168;
          cursor: pointer;
        }
      }
    }
  }
}
</style>

<style>
  .detailBox .detailImg img{
    width: 100%;
  }
</style>

