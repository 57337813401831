<template>
  <div id="breadCrumb">
    <el-breadcrumb separator-class="el-icon-arrow-right">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item v-for="(item,index) in breadCrumbList" :key="index">{{item}}</el-breadcrumb-item>
    </el-breadcrumb>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // 导航菜单内容
      breadCrumbList: ['商务印刷', '制品系列']
    };
  }
};
</script>
<style lang="scss" scoped>
div {
  width: 100%;
  background-color: #f0f0f0;
}
.el-breadcrumb {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  font-size: 14px;
  line-height: 40px;
}
</style>


